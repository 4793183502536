import React from 'react';
import './All.css';
import 'bootstrap/dist/css/bootstrap.css';
import ComparisonComponent from './Components/PhoneComparison/Comparison';

const HomeScreen = () => {
  return (
    <div className='HomeScreen'>
      <ComparisonComponent />

    </div>
  );
};

export default HomeScreen;
