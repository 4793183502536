import React, { useState, useEffect } from 'react';
import { TextField, Button, CircularProgress, Autocomplete, Box, Typography, Grid, IconButton, Chip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faSyncAlt, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';
import phoneNames from './PhoneNames/PhoneNames'; // Use this for all categories for now
import getPrompt from './Comparison/prompt.js'; // Default for phones
import { getLaptopPrompt } from './Comparison/prompts/laptopPrompt'; // Laptop specific
import { getTabletPrompt } from './Comparison/prompts/tabletPrompt'; // Tablet specific
import { getHeadPhonesPrompt } from './Comparison/prompts/HeadPhonesPrompt.js';
import { getWearablePrompt } from './Comparison/prompts/wearablePrompt'; // Wearable specific
import { geminiModel } from '../../firebase';
import './Comparison.css';
import { useNavigate, useLocation } from 'react-router-dom'; // Use useNavigate instead of useHistory

export default function ComparisonComponent() {
  const [device1, setDevice1] = useState('');
  const [device2, setDevice2] = useState('');
  const [loading, setLoading] = useState(false);
  const [comparisonResult, setComparisonResult] = useState('');
  const [darkMode, setDarkMode] = useState(false);
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const [deviceType, setDeviceType] = useState(urlParams.get('type') || 'phone');

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(savedDarkMode);
  }, []);

  const handleCompare = async () => {
    if (!device1.trim() || !device2.trim()) {
      setComparisonResult("Please enter valid device names to compare.");
      return;
    }

    setLoading(true);

    let prompt;
    switch (deviceType) {
      case 'laptop':
        prompt = getLaptopPrompt(device1, device2);
        break;
      case 'tablet':
        prompt = getTabletPrompt(device1, device2);
        break;
      case 'wearable':
        prompt = getWearablePrompt(device1, device2);
        break;
      case 'headphone':
        prompt = getHeadPhonesPrompt(device1, device2);
        break;
      default:
        prompt = getPrompt(device1, device2); // Default for phones
        break;
    }

    try {
      const result = await geminiModel.generateContent(prompt);
      const cleanResult = DOMPurify.sanitize(result.response.text());
      setComparisonResult(cleanResult);
    } catch (error) {
      console.error("Error generating comparison:", error);
      setComparisonResult("Failed to generate comparison. Please try again.");
    }

    setLoading(false);
  };

  const handleReCompare = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    handleCompare();
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
  };

  const handleDeviceTypeChange = (newType) => {
    setDeviceType(newType);
    navigate(`?type=${newType}`); // Use navigate to change the URL
    setDevice1(''); // Reset device selection
    setDevice2(''); // Reset device selection
  };

  const theme = {
    backgroundColor: darkMode ? '#000000' : '#FFFFFF',
    textColor: darkMode ? '#FFFFFF' : '#000000',
    cardBackground: darkMode ? '#1A1A1A' : '#FFFFFF',
    buttonColor: darkMode ? '#8A2BE2' : '#1E88E5',
    buttonHover: darkMode ? '#9A30F7' : '#1565C0',
    inputBackground: darkMode ? '#2C2C2C' : '#F1F8E9',
    borderColor: darkMode ? '#9A30F7' : '#1E88E5',
    accentColor: darkMode ? '#9A30F7' : '#1E88E5',
    inputTextColor: darkMode ? '#FFFFFF' : '#000000',
    dropdownBackground: darkMode ? '#333333' : '#FFFFFF', // Dropdown background in dark mode
    dropdownTextColor: darkMode ? '#FFFFFF' : '#000000',  // Dropdown text color in dark mode
  };

  return (
    <Box
      sx={{
        margin: '0 auto',
        padding: '1.5rem',
        backgroundColor: theme.backgroundColor,
        color: theme.textColor,
        textAlign: 'center',
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Grid container justifyContent="flex-end">
        <IconButton onClick={toggleDarkMode} sx={{ color: theme.textColor }}>
          <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
        </IconButton>
      </Grid>

      <Box
        className="Input-Box"
        sx={{
          margin: '0 auto',
          padding: '1.5rem',
          backgroundColor: theme.cardBackground,
          borderRadius: '16px',
          border: `2px solid ${theme.borderColor}`,
          textAlign: 'center',
          maxWidth: '600px',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ marginBottom: '1.5rem', fontWeight: 'bold' }}>
          Compare {deviceType.charAt(0).toUpperCase() + deviceType.slice(1)}s
        </Typography>

        <Box mt={2} display="flex" justifyContent="center" gap={2}>
          {['phone', 'laptop', 'tablet', 'wearable', 'headphone'].map((type) => (
            <Chip
              key={type}
              label={type.charAt(0).toUpperCase() + type.slice(1)}
              onClick={() => handleDeviceTypeChange(type)}
              sx={{
                backgroundColor: deviceType === type ? theme.buttonHover : theme.buttonColor,
                color: '#FFF',
                '&:hover': {
                  backgroundColor: theme.buttonHover,
                },
              }}
            />
          ))}
        </Box>

        <Grid container spacing={2} mt={2}>
          {['Device 1', 'Device 2'].map((label, index) => (
            <Grid item xs={12} key={label}>
              <Autocomplete
                freeSolo
                options={phoneNames} // Using phoneNames for all categories for now
                value={index === 0 ? device1 : device2}
                onInputChange={(event, newInputValue) =>
                  index === 0 ? setDevice1(newInputValue) : setDevice2(newInputValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    fullWidth
                    sx={{
                      backgroundColor: theme.inputBackground,
                      color: theme.textColor,
                      borderRadius: '50px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: theme.borderColor,
                        },
                        '&:hover fieldset': {
                          borderColor: theme.buttonHover,
                        },
                        '& input': {
                          color: theme.inputTextColor,
                        },
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <FontAwesomeIcon icon={faMobileAlt} style={{ marginRight: '8px', color: theme.accentColor }} />
                      ),
                    }}
                    InputLabelProps={{
                      style: { color: theme.textColor },
                    }}
                  />
                )}
                PaperComponent={({ children }) => (
                  <Box
                    sx={{
                      backgroundColor: theme.dropdownBackground,
                      color: theme.dropdownTextColor,
                    }}
                  >
                    {children}
                  </Box>
                )}
              />
            </Grid>
          ))}
        </Grid>

        <Button
          onClick={handleCompare}
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: theme.buttonColor,
            color: '#FFF',
            fontSize: '1rem',
            padding: '10px 0',
            marginTop: '16px',
            borderRadius: '40px',
            '&:hover': {
              backgroundColor: theme.buttonHover,
            },
          }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} sx={{ color: '#FFF' }} /> : `Compare ${deviceType.charAt(0).toUpperCase() + deviceType.slice(1)}s`}
        </Button>

        <Typography variant="caption" mt={2}>
  For the most accurate and up-to-date information, please check with your retailer.
</Typography>

      </Box>

      {comparisonResult && (
        <Box mt={3}>
          <div className="table-container" dangerouslySetInnerHTML={{ __html: comparisonResult }} />
          <Button
            onClick={handleReCompare}
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faSyncAlt} />}
            disabled={loading}
            sx={{
              marginTop: '16px',
              color: theme.buttonColor,
              borderColor: theme.buttonColor,
              borderRadius: '40px',
              '&:hover': {
                borderColor: theme.buttonHover,
                color: theme.buttonHover,
              },
            }}
          >
            Re-Compare
          </Button>
        </Box>
      )}

      <Typography variant="body2" mt={3} sx={{ color: '#888' }}>
        Powered by Gemini
      </Typography>

      <Typography variant="caption" sx={{  marginTop: '1rem' }}>
        If you find the response incorrect or isn't helpful, please press the re-compare button.
      </Typography>
    </Box>
  );
}
