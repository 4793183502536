import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';

// Custom theme for the AppBar
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Light blue for accents
    },
    secondary: {
      main: '#ffffff', // White text color
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(90deg, #000000 0%, #333333 100%)', // Black gradient background
          color: '#ffffff', // White text color
          boxShadow: 'none', // Remove the default shadow
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: '0 16px', // Adjust padding for consistency
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 'bold', // Bold text for prominence
          color: '#ffffff', // White text color
        },
      },
    },
  },
});

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return children
    ? React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
      })
    : null;
}

ElevationScroll.propTypes = {
  children: PropTypes.element,
  window: PropTypes.func,
};

export default function AppAppBar(props) {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <ElevationScroll {...props}>
          <AppBar>
            <Toolbar>
              <Typography variant="h6" component="div">
                <Link href="/" color="inherit" underline="none">
                  TechsCompare
                </Link>
              </Typography>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Toolbar /> {/* This creates space below the AppBar */}
      </React.Fragment>
    </ThemeProvider>
  );
}
