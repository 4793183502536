// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

const firebaseConfig = {
  apiKey: "AIzaSyAO8iZ19ltwf3XaQqfJD6aQf0EJoK3gdPI",
  authDomain: "cotech-eb372.firebaseapp.com",
  projectId: "cotech-eb372",
  storageBucket: "cotech-eb372.appspot.com",
  messagingSenderId: "985686988054",
  appId: "1:985686988054:web:412cf698da7a71e72a2c37",
  measurementId: "G-GK3BY6TYN2"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);  // Correct the order here

// Initialize Vertex AI
export const vertexAI = getVertexAI(firebaseApp);
export const geminiModel = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });
