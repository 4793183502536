  const phoneNames =  [
   "Galaxy S24 Ultra",
"Galaxy S24+",
"Galaxy S24",
"Galaxy Z Fold6",
"Galaxy Z Flip6",
"Galaxy A55",
"Galaxy A35",
"Galaxy A25",
"Galaxy A15",
"Galaxy M55",
"Galaxy M35",
"Galaxy M15",
"Galaxy Xcover7",
"Galaxy F series",
"Galaxy C series",
"Galaxy S23 Ultra",
"Galaxy S23+",
"Galaxy S23",
"Galaxy Z Fold5",
"Galaxy Z Flip5",
"Galaxy A54",
"Galaxy A34",
"Galaxy A24",
"Galaxy A14",
"Galaxy M54",
"Galaxy M34",
"Galaxy M14",
"Galaxy Xcover6 Pro",
"Galaxy S22 Ultra",
"Galaxy S22+",
"Galaxy S22",
"Galaxy Z Fold4",
"Galaxy Z Flip4",
"Galaxy A53",
"Galaxy A33",
"Galaxy A23",
"Galaxy A13",
"Galaxy M53",
"Galaxy M33",
"Galaxy M13",
"Galaxy Xcover5",
"Galaxy S21 Ultra",
"Galaxy S21+",
"Galaxy S21",
"Galaxy Z Fold3",
"Galaxy Z Flip3",
"Galaxy A52s",
"Galaxy A52",
"Galaxy A42 5G",
"Galaxy A32",
"Galaxy A22",
"Galaxy A12",
"Galaxy M52",
"Galaxy M42 5G",
"Galaxy M32",
"Galaxy M12",
"Galaxy Xcover Pro",
"Galaxy S20 Ultra",
"Galaxy S20+",
"Galaxy S20",
"Galaxy Z Fold2",
"Galaxy Z Flip",
"Galaxy A72",
"Galaxy A52 5G",
"Galaxy A42",
"Galaxy A32 5G",
"Galaxy A22 5G",
"Galaxy A12",
"Galaxy M62",
"Galaxy M52 5G",
"Galaxy M42",
"Galaxy M32 5G",
"Galaxy M22",
"Galaxy M12",
"Galaxy Note20 Ultra",
"Galaxy Note20",
"Galaxy Note10+",
"iPhone 15 Pro Max",
"iPhone 15 Pro",
"iPhone 15",
"iPhone 15 Plus",
"iPhone 14 Pro Max",
"iPhone 14 Pro",
"iPhone 14",
"iPhone 14 Plus",
"iPhone 13 Pro Max",
"iPhone 13 Pro",
"iPhone 13",
"iPhone 13 Mini",
"iPhone 12 Pro Max",
"iPhone 12 Pro",
"iPhone 12",
"iPhone 12 Mini",
"iPhone 11 Pro Max",
"iPhone 11 Pro",
"iPhone 11",
"iPhone XR",
"iPhone XS Max",
"iPhone XS",
"iPhone X",
"iPhone 8 Plus",
"iPhone 8",
"iPhone 7 Plus",
"iPhone 7",
"iPhone 6s Plus",
"iPhone 6s",
"iPhone 6 Plus",
"iPhone 6",
"iPhone SE (3rd generation)",
"iPhone SE (2nd generation)",
"iPhone SE",
"iPhone 5s",
"iPhone 5c",
"iPhone 5",
"iPhone 4S",
"iPhone 4",
"iPhone 3GS",
"iPhone 3G",
"iPhone" ,
"OPPO Reno12 Pro 5G",
"OPPO Reno10 5G",
"OPPO Reno8 Lite 5G",
"OPPO A77 5G",
"OPPO A17",
"OPPO Find X5 Pro",
"OPPO Find X5",
"OPPO Find X3 Pro",
"OPPO Find X3 Neo",
"OPPO Find X3 Lite",
"OPPO Reno7 Pro 5G",
"OPPO Reno7 5G",
"OPPO Reno7 Z 5G",
"OPPO Reno6 Pro 5G",
"OPPO Reno6 5G",
"OPPO Reno5 Pro 5G",
"OPPO Reno5 5G",
"OPPO Reno4 Pro",
"OPPO Reno4",
"OPPO Reno3",
"OPPO A95",
"OPPO A94",
"OPPO A93",
"OPPO A92",
"OPPO A75",
"OPPO A74",
"OPPO A55",
"OPPO A54",
"OPPO A53",
"OPPO A52",
"OPPO A51",
"OPPO F19 Pro+",
"OPPO F19 Pro",
"OPPO F19",
"OPPO F17 Pro",
"OPPO F17",
"OPPO F15",
"OPPO F11 Pro",
"OPPO F11",
"OPPO F9 Pro",
"OPPO F9",
"OPPO F7",
"OPPO F5",
"Xiaomi 14 Ultra",
"Xiaomi 14",
"Xiaomi 13T Pro",
"Xiaomi 13T",
"Xiaomi 13 Pro",
"Xiaomi 13",
"Xiaomi 12S Ultra",
"Xiaomi 12S Pro",
"Xiaomi 12S",
"Xiaomi 12 Pro",
"Xiaomi 12",
"Xiaomi 12X",
"Xiaomi 11T Pro",
"Xiaomi 11T",
"Xiaomi 11i HyperCharge",
"Xiaomi 11i",
"Xiaomi 11 Lite 5G NE",
"Xiaomi 11 Lite 5G",
"Xiaomi 11 Lite",
"Xiaomi 10T Pro",
"Xiaomi 10T Lite",
"Xiaomi 10T",
"Xiaomi 10 Pro",
"Xiaomi 10",
"Xiaomi Mi 9T Pro",
"Xiaomi Mi 9T",
"Xiaomi Mi 9 SE",
"Xiaomi Mi 9",
"Xiaomi Mi 8 Pro",
"Xiaomi Mi 8",
"Xiaomi Mi 8 Lite",
"Xiaomi Mi 8 SE",
"Xiaomi Mi A3",
"Xiaomi Mi A2",
"Xiaomi Mi A1",
"Redmi Note 13 Pro+",
"Redmi Note 13 Pro",
"Redmi Note 13",
"Redmi Note 12 Pro+",
"Redmi Note 12 Pro",
"Redmi Note 12",
"Redmi Note 11 Pro+",
"Redmi Note 11 Pro",
"Redmi Note 11",
"Redmi Note 10 Pro Max",
"Redmi Note 10 Pro",
"Redmi Note 10",
"Redmi Note 9 Pro Max",
"Redmi Note 9 Pro",
"Redmi Note 9",

"Redmi Note 8 Pro",
"Redmi Note 8",
"Redmi Note 7 Pro",
"Redmi Note 7",
"Redmi Note 6 Pro",
"Redmi Note 6",
"Redmi Note 5 Pro",
"Redmi Note 5",
"Redmi Note 4",
"Redmi Note 3",
"vivo X90 Pro+",
"vivo X90 Pro",
"vivo X90",
"vivo X80 Pro",
"vivo X80",
"vivo X70 Pro+",
"vivo X70 Pro",
"vivo X70",
"vivo X60 Pro+",
"vivo X60 Pro",
"vivo X60",
"vivo X50 Pro+",
"vivo X50 Pro",
"vivo X50",
"vivo V27 Pro",
"vivo V27",
"vivo V25 Pro",
"vivo V25",
"vivo V23 Pro",
"vivo V23",
"vivo V21",
"vivo V20 Pro",
"vivo V20",
"vivo Y76 5G",
"vivo Y75 5G",
"vivo Y72 5G",
"vivo Y70",
"vivo Y66",
"vivo Y55",
"vivo Y53s",
"vivo Y51",
"vivo Y50",
"vivo Y33s",
"vivo Y31",
"vivo Y30",
"vivo Y21T",
"vivo Y21",
"vivo Y20",
"vivo Y19",
"vivo Y18",
"vivo Y17",
"vivo Y16",
"vivo Y15s",
"vivo Y15",
"vivo Y12s",
"vivo Y12",
"vivo Y11",
"vivo Y10",
"vivo S17 Pro",
"vivo S17",
"vivo S16 Pro",
"vivo S16",
"vivo S15 Pro",
"vivo S15",
"vivo S12 Pro",
"vivo S12",
"vivo S10 Pro",
"vivo S10",
"realme GT 6",
"realme GT 6T",
"realme GT 5",
"realme GT 3",
"realme GT 2 Pro",
"realme GT 2",
"realme GT Neo 6",
"realme GT Neo 5",
"realme GT Neo 3",
"realme GT Neo 2",
"realme GT Neo",
"realme GT Master Edition",
"realme GT",
"realme X7 Pro",
"realme X7",
"realme X50 Pro",
"realme X50",
"realme X3 SuperZoom",
"realme X3",
"realme X2 Pro",
"realme X2",
"realme X",
"realme 11 Pro+",
"realme 11 Pro",
"realme 11",
"realme 10 Pro+",
"realme 10 Pro",
"realme 10",
"realme 9 Pro+",
"realme 9 Pro",
"realme 9i 5G",
"realme 9 5G",
"realme 9",
"realme 8s 5G",
"realme 8",
"realme 7 Pro",
"realme 7",
"realme 6 Pro",
"realme 6",
"realme 5 Pro",
"realme 5",
"realme 5i",
"realme 5s",
"realme C63",
"realme C61",
"realme C55",
"realme C53",
"realme C51",
"realme C50",
"realme C35",
"realme C33",
"realme C31",
"realme C30",
"realme C25Y",
"realme C25",
"realme C21Y",
"realme C21",
"realme C20",
"realme Narzo 60X",
"realme Narzo 60",
"realme Narzo 50 Pro",
"realme Narzo 50",
"realme Narzo 50A",
"realme Narzo 50i",
"realme Narzo 30A",
"Motorola Edge 40 Pro",
"Motorola Edge 40",
"Motorola Edge 30 Fusion",
"Motorola Edge 30 Pro",
"Motorola Edge 30",
"Motorola Edge 20 Fusion",
"Motorola Edge 20 Pro",
"Motorola Edge 20",
"Motorola Edge+",
"Motorola Edge",
"Motorola razr 40 Ultra",
"Motorola razr 40",
"Motorola razr 2022",
"Motorola razr 5G",
"Motorola razr",
"Moto G Stylus 5G (2023)",
"Moto G Stylus 5G (2022)",
"Moto G Stylus",
"Moto G Pure",
"Moto G Play",
"Moto G Power",
"Moto G Fusion",
"Moto G Fast",
"Moto G E6s",
"Moto G E6 Plus",
"Moto G E6",
"Moto G6 Plus",
"Moto G6",
"Moto G5S Plus",
"Moto G5S",
"Moto G5 Plus",
"Moto G5",
"Moto G4 Plus",
"Moto G4",
"Moto G3",
"Moto G2",
"Moto G",
"Huawei Mate 60 Pro+",
"Huawei Mate 60 Pro",
"Huawei Mate 60",
"Huawei Mate 50 Pro",
"Huawei Mate 50",
"Huawei Mate 40 Pro+",
"Huawei Mate 40 Pro",
"Huawei Mate 40",
"Huawei Mate 30 Pro",
"Huawei Mate 30",
"Huawei P60 Pro",
"Huawei P60",
"Huawei P60 Art",
"Huawei P50 Pro",
"Huawei P50",
"Huawei P40 Pro+",
"Huawei P40 Pro",
"Huawei P40",
"Huawei P30 Pro",
"Huawei P30",
"Huawei P20 Pro",
"Huawei P20",
"Huawei P10 Plus",
"Huawei P10",
"Huawei P9 Plus",
"Huawei P9",
"Huawei P8",
"Huawei P7",
"Huawei P6",
"Huawei nova 11 Ultra",
"Huawei nova 11 Pro",
"Huawei nova 11",
"Huawei nova 10 Pro",
"Huawei nova 10",
"Huawei nova 9",
"Huawei nova 8 Pro",
"Huawei nova 8 SE",
"Huawei nova 7 SE",
"Huawei nova 7i",
"Huawei nova 6",
"Huawei nova 5 Pro",
"Huawei nova 5i Pro",
"Huawei nova 5i",
"Huawei nova 4",
"Huawei nova 3",
"Huawei nova 2",
"Huawei nova",
"Huawei Y90",
"Huawei Y70",
"Huawei Y60",
"Huawei Y50",
"Huawei Y40",
"Huawei Y30",
"OnePlus 12",
"OnePlus 12R",
"OnePlus Nord 4",
"OnePlus Nord CE4 Lite 5G",
"OnePlus Nord 3 5G",
"OnePlus Nord CE 3 Lite 5G",
"OnePlus 11 5G",
"OnePlus 10T 5G",
"OnePlus Nord 2T 5G",
"OnePlus Nord CE 2 Lite 5G",
"OnePlus 10 Pro",
"OnePlus Nord CE 2 5G",
"OnePlus Nord2",
"OnePlus 9 Pro",
"OnePlus 9",
"OnePlus 9RT",
"OnePlus 8T",
"OnePlus 8 Pro",
"OnePlus 8",
"OnePlus Nord",
"OnePlus 7T Pro",
"OnePlus 7T",
"OnePlus 7 Pro",
"OnePlus 7",
"OnePlus 6T",
"OnePlus 6" ,
"Google Pixel 8 Pro",
"Google Pixel 8",
"Google Pixel 8a",
"Google Pixel 7 Pro",
"Google Pixel 7",
"Google Pixel 6 Pro",
"Google Pixel 6",
"Google Pixel 5a",
"Google Pixel 5",
"Google Pixel 4a (5G)",
"Google Pixel 4a",
"Google Pixel 4 XL",
"Google Pixel 4",


"Google Pixel 3a XL",
"Google Pixel 3a",
"Google Pixel 3 XL",
"Google Pixel 3",
"Nokia G50",
"Nokia G42",
"Nokia G310",
"Nokia G22",
"Nokia G21",
"Nokia G20",
"Nokia G11 Plus",
"Nokia G11",
"Nokia G10",
"Nokia C32",
"Nokia C22",
"Nokia C21 Plus",
"Nokia C21",
"Nokia C20 Plus",
"Nokia C20",
"Nokia C12",
"Nokia C11",
"Nokia C02",
"Nokia 8210 4G",
"Nokia 2660 Flip",
"Nokia 225 4G",
"Nokia 150",
"Nokia 105 Plus",
"Nokia 105",
"Sony WH-1000XM5",
"Bose QuietComfort Ultra",
"Apple AirPods Max",
"Bowers & Wilkins PX7 S2",
"Sennheiser Momentum 3 Wireless",
"Sony WH-1000XM4",
"Bose QuietComfort 45",
"Sennheiser Momentum 4 Wireless",
"Technics EAH-A800",
"Sony WH-CH720N",
"Anker Soundcore Space Q45",
"JBL Live 660NC",
"Sony WH-CH710N",
"Audio-Technica ATH-M50xBT",
"Philips SHP9500",
"Marshall Major IV",
"Audio-Technica ATH-M40x",
"Beyerdynamic DT 770 Pro",
"AKG K240 MK II",
"Sennheiser HD 25",
"Grado SR80e",
"AKG K240 MK II",
"Philips SHP9100",
"Sennheiser HD 25-1 II",
"Audio-Technica ATH-M30x",
"Sony MDR-V6",
"Beyerdynamic DT 990 Pro",
"AKG K240 MK II",
"Philips SHP9000",
"Sennheiser HD 25-13",
"Apple AirPods Pro 2",
"Sony WF-1000XM4",
"Bose QuietComfort Earbuds II",
"Sennheiser Momentum True Wireless 3",
"Sony WF-1000XM3",
"Bose QuietComfort Earbuds",
"Sennheiser Momentum True Wireless 2",
"Technics EAH-AZ80",
"Nothing Ear (2)",
"Nothing Ear (1)",
"Jabra Elite 7 Pro",
"Jabra Elite 85t",
"Samsung Galaxy Buds Pro",
"Samsung Galaxy Buds Live",
"Sony WF-C500",
"Beats Fit Pro",
"Beats Powerbeats Pro",
"Google Pixel Buds Pro",
"OnePlus Buds Pro 2",
"JBL Tour Pro 2",
"Bose SoundSport Wireless",
"Jaybird Vista 2",
"Shure Aonic 215",
"Sony LinkBuds S",
"Razer Hammerhead True Wireless",
"Anker Soundcore Liberty 4",
"Sennheiser CX Plus True Wireless",
"Skullcandy Indy Evo",
"Urbanista London",
"Lypertek PurePlay Z5",
"Final Audio ZE3000",
"Klipsch T5 II True Wireless",
"Master & Dynamic MW08",
"Marshall Minor III",
"JLab Epic Air Sport ANC",
"Amazfit PowerBuds Pro",
"Bang & Olufsen Beoplay EQ",
"Plantronics BackBeat Pro 5100",
"SoundPeats T2",
"Jabra Elite 3",
"Xiaomi Redmi Buds 4 Pro",
"Sony WF-XB700",
"Google Pixel Buds A-Series",
"Edifier NeoBuds Pro",
"1MORE ComfoBuds Pro",
"House of Marley Champion",
"Skullcandy Sesh Evo",
"Panasonic RZ-S500W",
"Samsung Galaxy Buds 2",
"Anker Soundcore Liberty Air 2 Pro",
"Shure Aonic Free",
"Realme Buds Air 3",
"Creative Outlier Air V3",
"Huawei FreeBuds Pro 2",
"Oppo Enco X2",
"NuraTrue Pro",
"Cambridge Audio Melomania 1+",
"Edifier TWS NB2 Pro",
"Ugreen HiTune X6",
"Honor Earbuds 3 Pro",
"LG Tone Free FP9",
"Anker Soundcore Life P3",
"SoundPeats Air3 Deluxe HS",
"Motorola VerveBuds 120",
"JBL Tune 230NC",
"Tozo NC9",
"Skullcandy Push Ultra",
"Mpow M30 Plus",
"TaoTronics SoundLiberty 92",
"BlitzWolf BW-FYE7",
"Enacfire E60",
"Tribit FlyBuds C1",
"Soundcore Spirit Dot 2",
"Tronsmart Apollo Bold",
"Fiil T1X",
"1MORE Stylish True Wireless",
"Apple Watch Series 8",
"Samsung Galaxy Watch 6 Classic",
"Garmin Forerunner 955",
"Fitbit Sense 2",
"Huawei Watch GT 4",
"Polar Vantage V2",
"TicWatch Pro 3 GPS",
"Amazfit GTR 4",
"Withings ScanWatch",
"Coros Pace 2",
"Oura Ring Generation 3",
"Mobvoi TicWatch Pro X",
"Xiaomi Mi Watch Lite",
"Honor MagicWatch 2",
"Withings Move ECG",
"Garmin Venu 2 Plus",
"Suunto 9 Peak Pro",
"TicWatch E3",
"Amazfit GTS 4 Mini",
"Mobvoi TicWatch C2+",
"Fitbit Charge 5",
"Xiaomi Mi Band 8",
"Honor Band 7",
"Garmin Vivoactive 4S",
"Polar Ignite 2",
"Suunto Spartan Sport Wrist HR",
"Withings Body+",
"Withings Pulse HR",
"Garmin Vivofit 4",
"Fitbit Inspire 3",
"Amazfit Band 7",
"Mobvoi TicWatch GTH",
"Xiaomi Mi Band 7 Pro",
"Honor Band 6",
"Fitbit Luxe",
"Garmin Vivomove Style",
"Suunto Core All-Black",
"Withings Body Cardio",
"Garmin Vivofit Jr. 4",
"Fitbit Ace 3",
"Apple Watch Series 8 (ECG, Blood Oxygen)",
"Samsung Galaxy Watch 6 Classic (ECG, Blood Pressure)",
"Withings ScanWatch (ECG, Blood Oxygen, Sleep Apnea)",
"Garmin Forerunner 955 (Advanced Running Metrics)",
"Polar Vantage V2 (Recovery Metrics)",
"Suunto 9 Peak Pro (Advanced Training Metrics)",
"Oura Ring Generation 3 (Sleep, Stress, Activity)",
"Mobvoi TicWatch Pro 3 GPS (Health Metrics)",
"Xiaomi Mi Band 8 (Health Metrics)",
"Honor Band 7 (Health Metrics)",
"Fitbit Sense 2 (Stress, ECG, Blood Oxygen)",
"Garmin Venu 2 Plus (Health Metrics)",
"Withings Move ECG (ECG)",
"Garmin Vivofit 4 (Activity, Sleep)",
"Fitbit Inspire 3 (Activity, Sleep)",
"Amazfit Band 7 (Health Metrics)",
"Mobvoi TicWatch GTH (Health Metrics)",
"Xiaomi Mi Band 7 Pro (Health Metrics)",
"Honor Band 6 (Health Metrics)",
"Fitbit Luxe (Health Metrics)",
"iPad Pro 12.9-inch (M2)",
"iPad Pro 11-inch (M2)",
"iPad Air (5th generation)",
"iPad (10th generation)",
"iPad mini (6th generation)",
"Samsung Galaxy Tab S9 Ultra",
"Samsung Galaxy Tab S9+",
"Samsung Galaxy Tab S9",
"Google Pixel Tablet",
"Lenovo Tab P12 Pro",
"Xiaomi Pad 6 Pro",
"Huawei MatePad Pro 12.6-inch",
"Honor Pad V8",
"Amazon Fire HD 10 Plus",
"Amazon Fire HD 10",
"Amazon Fire HD 8 Plus",
"Amazon Fire HD 8",
"TCL Tab 10 Plus",
"Nokia T20",
"Realme Pad Mini",
"Microsoft Surface Go 3",
"Microsoft Surface Pro 9",
"Microsoft Surface Duo 2",
"Lenovo ThinkPad X12 Detachable",
"Huawei MatePad Pro 11-inch",
"Honor Pad 7",
"Amazon Fire HD 10 Kids Edition",
"Amazon Fire HD 8 Kids Edition",
"TCL Tab 10",
"Nokia T20 Lite",
"Realme Pad",
"Chuwi Hi10 Go",
"Alldocube iPlay 40",
"Teclast P40 Pro",
"Blackview Tab 11",
"Alienware x17 R2",
"Razer Blade 15 Advanced",
"Asus ROG Strix G17",
"MSI GE76 Raider",
"Gigabyte AORUS 17X",
"Lenovo Legion 7i",
"HP Omen 17",
"Acer Predator Helios 17",
"Dell G15",
"Asus TUF Gaming F15",
"Apple MacBook Pro 16-inch (M2 Pro/Max)",
"Apple MacBook Pro 14-inch (M2 Pro/Max)",
"Dell XPS 13",
"Lenovo ThinkPad X1 Carbon",
"HP EliteBook x360 1040 G9",
"Microsoft Surface Laptop Studio",
"Microsoft Surface Laptop Go 2",
"Acer Aspire Vero",
"Asus ZenBook 14X OLED",
"Lenovo ThinkPad X1 Yoga",
"Apple MacBook Air (M2)",
"Dell XPS 13 2-in-1",
"Lenovo Yoga Slim 7i",
"HP Spectre x360 14",
"Acer Swift X",
"Asus ZenBook S 13 OLED",
"LG Gram 16",
"Huawei MateBook X Pro",
"Microsoft Surface Pro 9",
"Lenovo IdeaPad Flex 5",
"Acer Aspire 5",
"Lenovo IdeaPad 3",
"HP Pavilion 15",
"Dell Inspiron 15",
"Asus VivoBook 15",
"Lenovo Chromebook Duet",
"HP Chromebook x360",
"Acer Chromebook 315",
"Asus Chromebook Flip C436",
"Lenovo ThinkPad E14",
"Microsoft Surface Pro 9",
"Lenovo Yoga 7i",
"HP Spectre x360 14",
"Acer Spin 5",
"Asus ZenBook Flip 13",
"Lenovo IdeaPad Flex 5",
"HP Pavilion Aero 2-in-1",
"Acer Aspire 5 2-in-1",
"Asus VivoBook Flip 14",
"Lenovo ThinkPad X12 Detachable"
];
export default phoneNames;