export function getTabletPrompt(tablet1, tablet2) {
  return `
    Please compare the following two tablets:
      - Tablet 1: ${tablet1}
      - Tablet 2: ${tablet2}

    Fetch all the information from reliable sources.

    I need a detailed comparison in **pure HTML format**, covering every possible specification for each tablet.

    **Specifications to include:**
    1. **Display**:
        - Type (e.g., IPS LCD, OLED)
        - Size (in inches)
        - Resolution (in pixels)
        - Refresh rate (Hz)
        - Brightness (in nits)
        - Aspect ratio
        - Touch response rate (in Hz)
        - Screen protection (e.g., Gorilla Glass, scratch-resistant)

    2. **Processor**:
        - Chipset (e.g., Apple M1, Qualcomm Snapdragon)
        - Number of cores and clock speed (e.g., Octa-core, 2.84GHz)
        - GPU model (e.g., Adreno 660, Apple GPU)

    3. **Memory and Storage**:
        - RAM size (in GB)
        - Storage options (in GB or TB)
        - Expandable storage (Yes/No, and type)

    4. **Camera**:
        - Rear camera setup (number of cameras, megapixels, features like autofocus or OIS)
        - Front camera setup (megapixels, features like portrait mode)
        - Video recording capabilities (e.g., 4K at 30fps, 1080p at 60fps)

    5. **Battery**:
        - Capacity (in mAh)
        - Battery life (in hours, screen-on time, video playback, etc.)
        - Charging speed (wired and wireless)
        - Special features (e.g., fast charging, reverse charging)

    6. **Operating System and Software**:
        - OS version (e.g., iPadOS, Android, Windows)
        - Custom software features or skins (e.g., Samsung One UI, iPad multitasking)
        - App ecosystem and app store compatibility (mention if there are exclusive apps)
        - Stylus support (e.g., Apple Pencil, S Pen)

    7. **Connectivity**:
        - Network support (e.g., Wi-Fi only, LTE, 5G)
        - Bluetooth version (e.g., 5.1)
        - USB type (e.g., USB-C, Lightning)
        - External accessory support (e.g., keyboard, mouse)
        - GPS, NFC, or other communication standards

    8. **Build and Design**:
        - Materials used (e.g., aluminum, glass)
        - Dimensions (in mm)
        - Weight (in grams)
        - Color options
        - Water resistance rating (e.g., IP68)

    9. **Audio**:
        - Speaker setup (e.g., stereo, quad speakers)
        - Audio features (e.g., Dolby Atmos, Hi-Res Audio)
        - 3.5mm headphone jack (Yes/No)
        - Microphone quality

    10. **Accessories**:
        - Compatibility with keyboard covers or cases
        - Availability of official or third-party accessories
        - Stylus or pen support (mention specific models)

    11. **Price**:
        - Include the official price in USD, EUR, JPY, and GBP for the default models. Mention this clearly.
        - Make sure the prices are all in the same line.

    12. **Release Date**:
        - The official launch date of the tablets.

    **HTML Table Format:**
    - The comparison **must** be provided as a clean, simple HTML table using only these tags: <table>, <tr>, <th>, and <td>.
    - There should be no bullet points or unnecessary text outside the table.
    - Compare Tablet 1 and Tablet 2 side by side.
    - Ensure the table is well-organized with clear column headers for each specification (e.g., "Tablet 1", "Tablet 2").
    - Make sure each row has one specification (e.g., "Display", "Processor", etc.) with both tablets’ details in separate columns.

    Example HTML structure:

    \`\`\`
    <table>
      <tr>
        <th>Specification</th>
        <th>Tablet 1</th>
        <th>Tablet 2</th>
      </tr>
      <tr>
        <td>Display</td>
        <td>11-inch, OLED, 120Hz, 1600x2560 pixels</td>
        <td>10.5-inch, IPS LCD, 60Hz, 1920x1200 pixels</td>
      </tr>
      <tr>
        <td>Processor</td>
        <td>Apple M1, Octa-core, 3.2GHz</td>
        <td>Qualcomm Snapdragon 888, Octa-core, 2.84GHz</td>
      </tr>
      <!-- Continue for all specifications -->
    </table>
    \`\`\`

    **Points-Based Verdict:**
    - Calculate a **total score** for each tablet out of 100, based on all specification categories.
    - Format the result as:

      - Tablet 1: Name [Total Points out of 100] points
      - Tablet 2: Name [Total Points out of 100] points
     <h4>Tablet Scores</h4>
     <h5>Tablet1 and Total points out of 100 </h5>
     <h5>Tablet2 and Total points out of 100 </h5>

     <h4>Winner</h4>
     <h5>The tablet with the higher points is declared as the winner</h5>
     <h6>Short summary of why this tablet is the winner</h6>

    Provide the response only in plain HTML format with no additional text or commentary.
  `;
}
